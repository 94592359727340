import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { RoleAssignMenuModel } from '../models/roleAssignMenuModel.model';
import { PaginationModel } from '../models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient, private authService: AuthService) {}

  getMenuItems(): Observable<any> {
    let localUserData: string | null = localStorage.getItem('auth_app_user');
    if (localUserData == null) throw new Error('User is not logged in');
    if (this.authService.localUser == null)
      throw new Error('User data not found in auth service');

    return this.http
      .get<any>(`${this.baseUrl}/AppMenu/${this.authService.localUser.userId}/`)
      .pipe(map((response) => response));
  }

  getAllMenus(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/AppMenu/`);
  }

  assignMenu(roleAssignModel: RoleAssignMenuModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/RoleAssign/`, roleAssignModel);
  }

  getRoleList(
    paginationModel: PaginationModel<RoleAssignMenuModel>
  ): Observable<any> {
    let parameter = new HttpParams();

    parameter = parameter.set(
      'pageNumber',
      paginationModel.pageNumber.toString()
    );
    parameter = parameter.set(
      'pageLength',
      paginationModel.pageLength.toString()
    );
    parameter = parameter.set(
      'activeStatus',
      paginationModel.activeStatus.toString()
    );

    /*
      if (activeStatus != null) {
        parameter = parameter.set('activeStatus', activeStatus.toString());
      }
      */

    if (paginationModel.searchString != null) {
      parameter = parameter.set(
        'searchString',
        paginationModel.searchString.toString()
      );
    }
    return this.http.get<any>(`${this.baseUrl}/GetPagedList`, {
      params: parameter,
    });
  }

  getMenuByRoleId(roleId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${roleId}`);
  }

  updateRoleMenu(roleAssignModel: RoleAssignMenuModel): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/UpdateRoleMenuAsync`,
      roleAssignModel
    );
  }

  statusUpdate(
    systemIdIdList: Array<number>,
    status: boolean
  ): Observable<any> {
    console.log('SystemIdList', systemIdIdList);
    return this.http.post<any>(
      `${this.baseUrl}/SetStatusAsync?status=${status}`,
      systemIdIdList
    );
  }
}
