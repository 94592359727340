import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { UrlCheckGuard } from './shared/url-check.guard';
import { AppQrDownloadComponent } from './app-qr-download/app-qr-download.component';
import { AuthRedirectGuard } from './shared/auth/auth-redirect-guard.guard';
import { ProcessingComponent } from './processing/processing.component';
import { ProcessingUrlCheckGuard } from './shared/processing-url-check.guard';

const routes: Routes = [
  //#region Private Routes
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard], // here we tell Angular to check the access with our AuthGuard
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'food-court',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./food-court/food-court.module').then((m) => m.FoodCourtModule),
  },
  //#endregion

  //#region Public Routes
  {
    path: 'auth',
    canActivate: [AuthRedirectGuard],
    loadChildren: () =>
      import('./new-auth/new-auth.module').then((m) => m.NewAuthModule),
  },
  // {
  //   path: 'authold',
  //   //canActivate: [AuthRedirectGuard],
  //   loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  // },
  {
    path: 'paymentStatus',
    canActivate: [ProcessingUrlCheckGuard],
    component: ProcessingComponent,
  },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  // },
  // {
  //   path: 'beforeApprove',
  //   component: BeforeApproveComponent,
  // },
  {
    path: 'qrdownload/:customUrl',
    component: AppQrDownloadComponent,
  },
  {
    path: ':customUrl',
    canActivate: [UrlCheckGuard],
    loadChildren: () =>
      import('./customer/customer.module').then((c) => c.CustomerModule),
  },
  //#endregion
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
