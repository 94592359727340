import { AddOn } from './addon.model';
import { DiscountModel } from './discountModel.model';
import { ProductTypeModel } from './productType.model';

export class ProductModel {
  productMasterId: number = 0;
  productName: string = '';
  productDisplayName: string = '';
  productCode: string = '';
  entityProductCode: string = '';
  productApprxWeight: number = 0;
  productDescription: string = '';
  productIngredientDetails: string = '';
  productTypeId: number = 0;
  productImgURL: string = '';
  productCategoryName: string = '';
  defaultImg: number = 0;
  defaultImgStr: string = '';
  productImgURLs: Array<ProductImageModel> = [];
  entityId: number = 0;
  unitId: number = 0;
  sellPrice: number = 0;
  discountPrice: number = 0;
  foodTypeId: number = 0;
  costPrice: number = 0;
  isActive: boolean = true;
  vatPercent: number = 0;
  productType: ProductTypeModel = new ProductTypeModel();
  productVariants: Array<ProductVariant> = new Array<ProductVariant>();
  addonsMasterIds: number[] = [];
  productIngredients: string = '';
  campaignDiscount: DiscountModel | null = null;
  quantity: number = 0;
  isAddOnsItem: boolean = false;
  isChecked: boolean = false;
  productAddons: Array<AddOn> = new Array<AddOn>();
  activeStatus: boolean = true;
  vatType: number = 0;
}

export class ProductVariant {
  /**
   * Primary key of variant / attribute / outlet wise product mapping
   * @type {Number}
   */
  variantId: number = 0;
  /**
   * Display name of the variant
   * @type {string}
   */
  variantName: string = '';
  /**
   * Primary key of the outlet that is selling this item
   * @type {number}
   */
  outletId: number = 0;
  /**
   * Display name of the outlet that is selling this item (Mostly necessary for display purposes)
   * @type {string}
   */
  outletName: string = '';
  /**
   * Primary key of the master product this item is a variant of
   * @type {Number}
   */
  productMasterId: number = 0;
  /**
   * Name of the master product this item is a variant of (Mostly necessary for display purposes)
   * @type {string}
   */
  productName: string = '';
  /**
   * Name of the measuring unit of this product (Mostly necessary for display purposes)
   * @type {string}
   */
  unitName: string = '';
  /**
   * Product quantity in a single unit
   * @type {Number}
   */
  weightValue: number = 0;
  /**
   * Primary key of the measuring unit of this product
   * @type {Number}
   */
  discountPrice: number = 0;
  /**
   * The quantity of the product.
   * @type {Number}
   */
  quantity: number = 1;
  /**
   * Indicates whether the product is currently selected.
   * @type {boolean}
   */
  isSelected: boolean = false;
  weightId: number = 0;
  sellPrice: number = 0;
  costPrice: number = 0;
  minsalePrice: number = 1.0;
  isAddon: boolean = false;
  isActive: boolean = true;
  vatAmount: number = 0;
  specification: string = '';
}

export class AddOnsMappingModel {
  productMasterId: number = 0;
  productName: string = '';
  entityId: number = 0;
  outletId: number = 0;
  addonsMasterId: number[] = [];
}

export class ProductImageModel {
  isDefault: boolean = true;
  imageUrl: string = '';
  imageSequence: number = 0;
  imgBin: string = '';
}

export class ProductAddonsModel {
  productMasterId: number = 0;
  productName: string = '';
  productDisplayName: string = '';
  productDescription: string = '';
  productIngredientDetails: string = '';
  categoryId: number = 0;
  entityId: number = 0;
  productApprxWeight: number = 0;
  specification: string = '';
  unitName: string = '';
  weightId: number = 0;
  weightValue: number = 0;
  sellPrice: number = 0;
  foodTypeId: number = 0;
  costPrice: number = 0;
  isActive: boolean = true;
  OutletWiseProduct: Array<OutletWiseProduct> = [];
}
export class OutletWiseProduct {
  entityChildId: number[] = [];
  attributeId: number = 0;
  attributeName: string = '';
  productMasterId: number = 0;
  productName: string = '';
  costPrice: number = 0;
  sellPrice: number = 0;
  minsalePrice: number = 1.0;
  specification: number = 0.0;
  weightId: number = 0;
  isActive: boolean = true;
}
