import { createReducer, on } from "@ngrx/store";
import { setSubscriptionRenew, updateEntityWiseSubscription } from "./subscription.action";

export interface EntityState {
  entityWiseSubscription: any;
}

export const initialState: EntityState = {
  entityWiseSubscription: null,
};

export const entityReducer = createReducer(
  initialState,
  on(updateEntityWiseSubscription, (state, { entityWiseSubscription }) => ({
    ...state,
    entityWiseSubscription,
  }))
);


export interface SubscriptionRenewState {
  value: boolean;
}

export const initialRenewState: SubscriptionRenewState = {
  value: false, // Default to false
};

// Create the reducer
export const subscriptionRenewReducer = createReducer(
  initialRenewState,
  on(setSubscriptionRenew, (state, { value }) => ({ ...state, value }))
);
