import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../services/application.service';
import { SubscriptionService } from '../../services/subscription.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionAuthGuard implements CanActivate {
  constructor(
    private appService: ApplicationService,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const routePath = route.routeConfig?.path || '';
    const outletId = localStorage.getItem('selectedOutletId');
    console.log("matchedMenu=======================>", routePath);
    if (!outletId) {
      this.router.navigate(['/dashboard']);
      return of(false);
    }



    return this.appService.getMenuItems().pipe(
      map((menuItems: any) => {
        if (routePath == 'home') {
          this.router.navigate(['/dashboard/home']);
          return null;
        }

        const matchedMenu = menuItems.find((menu: any) => {
          console.log("routePath:", routePath);
          console.log("menu.controller:", menu);
          return menu.controllerName.includes(routePath);
        });



        if (!matchedMenu || matchedMenu == undefined) {
          this.router.navigate(['/dashboard']);
          return null;
        }

        return matchedMenu.menuId;
      }),
      switchMap((menuId) => {
        if (!menuId) {
          return of(false);
        }

        if (['products', 'category'].includes(routePath)) {
          return this.subscriptionService
            .GetSubscriptionModuleStatus(menuId, Number(outletId))
            .pipe(
              map((response) => {
                const isSubscribed = response.content.isEntitySubscribed;
                if (!isSubscribed) {
                  this.router.navigate(['/dashboard']); // Redirect if not subscribed
                }
                return isSubscribed;
              }),
              catchError(() => {
                this.router.navigate(['/dashboard']); // Redirect on error
                return of(false);
              })
            );
        } else {
          return this.subscriptionService
            .GetSubscriptionGlobalStatus(menuId)
            .pipe(
              map((response) => {
                const isSubscribed = response.content.isEntitySubscribed;
                if (!isSubscribed) {
                  this.router.navigate(['/dashboard']); // Redirect if not subscribed
                }
                return isSubscribed;
              }),
              catchError(() => {
                this.router.navigate(['/dashboard']); // Redirect on error
                return of(false);
              })
            );
        }
      }),
      catchError(() => {
        this.router.navigate(['/dashboard']); // Redirect on error
        return of(false);
      })
    );

  }
}
