import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'PriyoShop.Retail.UI';
  constructor(private router: Router, private _spinner: NgxSpinnerService, private authService: AuthService) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this._spinner.show(); // Show the spinner when navigation starts
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this._spinner.hide(); // Hide the spinner when navigation ends or fails
      }
    });
  }

  ngOnInit() {
    this.authService.getUserIp().subscribe((response) => {
      // this.userIp = response.IpAddress; // Bind IP address to the view
      console.log("ip from app component ========> ", response.IpAddress);
      
    });
  }
}
