export const environment = {
  // ========== TEST SERVER =========================
  //  apiUrl: 'http://192.168.102.243:7000',
  //  imgUrl: 'http://192.168.102.243:7000/images/',

  // ========== LIVE =========================
  // apiUrl: 'https://api.simplepos.xyz',
  // imgUrl: 'https://api.simplepos.xyz/images/',
  // sampleImgUrl: 'https://blob.simplepos.xyz/imageLibrary/',

  // ========== DEMO =========================
  apiUrl: 'https://demoapi.simplepos.xyz',
  imgUrl: 'https://demoblob.simplepos.xyz/',
  sampleImgUrl: 'https://demoblob.simplepos.xyz/imageLibrary/',

  production: true,
  firebaseConfig: {
    // to cut the chase, just copy it the snippet on step 3
    apiKey: "AIzaSyBRV-Kdi10t-JCNik8TIDUoZXXDQ1OoGm0",
    authDomain: "simplepos-ee87e.firebaseapp.com",
    projectId: "simplepos-ee87e",
    storageBucket: "simplepos-ee87e.firebasestorage.app",
    messagingSenderId: "1028104427230",
    appId: "1:1028104427230:web:0ba1615a0abc87d8ea144f",

    // You are not yet have this
    vapidKey: "BErh2nZnrg3hoMO08CrGhyYRROjLjOAfUwWGSNqNV-_CRvQJGUMz0pj-3ME_1bhkzSBNA-o-_tbz7L37rgBqf-s"
  }
};
