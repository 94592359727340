import { createAction, props } from "@ngrx/store";

export const updateEntityWiseSubscription = createAction(
  '[Entity Reducer] Update Subscription',
  props<{ entityWiseSubscription: any }>()
);


export const setSubscriptionRenew = createAction(
  '[Boolean State] Set Boolean',
  props<{ value: boolean }>()
);
